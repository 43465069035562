import { GALLERY_CONSTS } from 'pro-gallery-lib';
var mobileDefaults = {
  numbers: {
    galleryLayout: 0,
    gallerySize: 30,
    loveCounter: 0,
    gallerySliderImageRatio: 0,
    galleryImageRatio: 2,
    numberOfImagesPerRow: 1,
    numberOfImagesPerCol: 1,
    collageDensity: 80,
    borderRadius: 0,
    imageMargin: 6,
    galleryMargin: 0,
    floatingImages: 0,
    galleryHorizontalAlign: 1,
    galleryVerticalAlign: 1,
    itemClick: 0,
    slideshowInfoSize: 160,
    thumbnailSize: 90,
    galleryThumbnailsAlignment: 0,
    thumbnailSpacings: 4,
    gridStyle: 0,
    titlePlacement: 0,
    autoSlideshowInterval: 4,
    arrowsSize: 23,
    loadMoreButtonBorderWidth: 1,
    loadMoreButtonBorderRadius: 0,
    scrollDirection: GALLERY_CONSTS.scrollDirection.VERTICAL,
    imageResize: 0,
    slideshowLoop: 0,
    isAutoSlideshow: 0,
    customButtonBorderWidth: 1,
    customButtonBorderRadius: 0,
    magicLayoutSeed: 1,
    mobileLoadMoreSize: 10,
    imageLoadingMode: 1,
    imageLoadingWithColorMode: 0,
    scrollAnimation: 0,
    overlayAnimation: 0,
    imageHoverAnimation: 0,
    mobileSwipeAnimation: 0,
    expandAnimation: 0,
    galleryAlignExpand: 0,
    itemBorderWidth: 0,
    itemBorderRadius: 0,
    itemShadowBlur: 20,
    itemShadowDirection: 135,
    itemShadowSize: 10,
    hoveringBehaviour: 0,
    loadMoreAmount: 0,
    arrowsPosition: 0,
    imageRatioType: 0,
    numberOfDisplayedItems: 3,
    slideAnimation: 0,
    overlayGradientDegrees: 180,
    slideTransition: 1,
    scrollDuration: 0.8
  },
  fonts: {
    itemFont: {
      editorKey: 'font_5',
      size: 22
    },
    itemFontSlideshow: {
      editorKey: 'font_5'
    },
    itemDescriptionFont: {
      editorKey: 'font_8'
    },
    itemDescriptionFontSlideshow: {
      editorKey: 'font_8'
    },
    customButtonFontForHover: {
      editorKey: 'font_8'
    },
    customButtonFont: {
      editorKey: 'font_8'
    },
    loadMoreButtonFont: {
      editorKey: 'font_8'
    },
    titleFontExpand: {
      editorKey: 'font_5'
    },
    descriptionFontExpand: {
      editorKey: 'font_8'
    }
  },
  colors: {
    itemFontColor: 'color-1',
    itemFontColorSlideshow: 'color-5',
    itemDescriptionFontColor: 'color-1',
    itemDescriptionFontColorSlideshow: 'color-5',
    customButtonFontColorForHover: 'color-5',
    customButtonFontColor: 'color-1',
    loadMoreButtonFontColor: 'color-5',
    itemOpacity: 'color-5',
    itemIconColorSlideshow: 'color-5',
    itemIconColor: 'color-1',
    arrowsColor: 'color-1',
    customButtonColor: 'color-1',
    customButtonBorderColor: 'color-1',
    loadMoreButtonColor: 'color-1',
    imageLoadingColor: 'color-2',
    oneColorAnimationColor: 'color-1',
    loadMoreButtonBorderColor: 'color-5',
    bgColorExpand: 'color-1',
    actionsColorExpand: 'color-5',
    titleColorExpand: 'color-5',
    descriptionColorExpand: 'color-5',
    itemBorderColor: 'color-5',
    itemShadowOpacityAndColor: 'color-5',
    overlayGradientColor1: 'color-5',
    overlayGradientColor2: 'color-5'
  },
  opacities: {
    itemOpacity: '0.60',
    loadMoreButtonColor: '1',
    customButtonColor: '0',
    itemShadowOpacityAndColor: '0.2',
    overlayGradientColor1: '0.01',
    overlayGradientColor2: '0.60'
  },
  booleans: {
    isVertical: false,
    allowSocial: true,
    allowDownload: false,
    allowTitle: true,
    allowDescription: false,
    loveButton: true,
    useWatermark: true,
    fullscreenLoop: false,
    allowLinkExpand: true,
    itemEnableShadow: false,
    enableFullscreenVideoPlaceholder: true
  }
};
export default {
  mobileDefaults: mobileDefaults
};